import END_POINTS from 'services/endPoints';
import api from '../api';

const getClubs = (options = { pagination: { limit: 100 } }) => api(
  END_POINTS.clubs,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getClubs;
